import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Link,
  Box,
  Section,
  Image,
  Span,
  List,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Главная | гольф-клуб The Green Expanse</title>
        <meta
          name={"description"}
          content={"Где каждый взмах рассказывает свою историю"}
        />
        <meta
          property={"og:title"}
          content={"Главная | гольф-клуб The Green Expanse"}
        />
        <meta
          property={"og:description"}
          content={"Где каждый взмах рассказывает свою историю"}
        />
        <meta
          property={"og:image"}
          content={"https://yoxemfrux.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://yoxemfrux.com/img/2047953.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://yoxemfrux.com/img/2047953.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://yoxemfrux.com/img/2047953.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://yoxemfrux.com/img/2047953.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://yoxemfrux.com/img/2047953.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://yoxemfrux.com/img/2047953.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="40px 0 40px 0"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://yoxemfrux.com/img/1.jpg) center center/cover no-repeat"
        min-height="100vh"
        lg-padding="80px 0 80px 0"
        md-padding="96px 0 96px 0"
        sm-padding="72px 0 96px 0"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="100%"
          flex-direction="column"
          justify-content="center"
          align-items="center"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 40px 0px"
          margin="0px 0px 0px 0px"
          padding="0px 0px 0px 0px"
          md-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--light"
            font="--headline1"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            text-align="center"
            width="80%"
            md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            sm-width="100%"
          >
            гольф-клуб The Green Expanse
          </Text>
          <Text
            margin="0px 0px 32px 0px"
            color="#FFFFFF"
            font="--lead"
            lg-text-align="center"
            text-align="center"
            max-width="900px"
          >
            Добро пожаловать в гольф-клуб The Green Expanse - место, где страсть
            к гольфу сочетается с безмятежностью природы. Наш клуб предназначен
            как для энтузиастов, так и для профессионалов, стремящихся
            насладиться прекрасной игрой в гостеприимной и инклюзивной
            обстановке. Каждое посещение клуба обещает сочетание расслабления,
            испытания и товарищеского общения с коллегами по гольфу.
          </Text>
          <Link
            href="/contacts"
            padding="12px 24px 12px 24px"
            color="--light"
            background="--color-primary"
            text-decoration-line="initial"
            font="--lead"
			border="1px solid #fff"
            border-radius="8px"
            margin="0px 0px 0px 0px"
            transition="background-color 0.2s ease-in-out 0s"
            hover-background="--color-orange"
            sm-margin="0px 0px 0px 0px"
            sm-text-align="center"
            hover-transition="background-color 0.2s ease-in-out 0s"
          >
            Контакты
          </Link>
        </Box>
      </Section>
      <Section
        padding="60px 0"
        sm-padding="40px 0"
        min-height="600px"
        sm-min-height="auto"
        background="--color-light"
        display="flex"
        flex-direction="column"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Text
          font="--base"
          text-transform="uppercase"
          letter-spacing="1px"
          color="--grey"
          margin="0px"
          width="100%"
        >
          Добро пожаловать на нашу поляну
        </Text>
        <Text
          as="h1"
          margin="8px 0px 32px"
          font="--headline1"
          md-font="--headline2"
          color="--dark"
          width="100%"
        >
          гольф-клуб The Green Expanse
        </Text>
        <Text
          margin="0px"
          max-width="600px"
          font="--base"
          color="--grey"
          padding="0px 16px 0px 0px"
          width="66.66%"
          md-width="100%"
          md-padding="0px 0px 0px 0px"
          md-margin="0px 0px 24px 0px"
        >
          Добро пожаловать в гольф-клуб The Green Expanse - место, где страсть к
          гольфу сочетается с безмятежностью природы. Наш клуб предназначен как
          для энтузиастов, так и для профессионалов, стремящихся насладиться
          прекрасной игрой в гостеприимной и инклюзивной обстановке.
        </Text>
        <Text
          margin="0px 0px 0px auto"
          font="--base"
          color="--grey"
          width="20%"
          md-width="100%"
          md-margin="0px 0px 32px 0px"
        >
          Каждое посещение клуба обещает сочетание расслабления, испытания и
          товарищеского общения с коллегами по гольфу.
        </Text>
      </Section>
      <Section
        padding="90px 0 90px 0"
        sm-padding="40px 0"
        min-height="600px"
        sm-min-height="auto"
        display="flex"
        background="--color-darkL1"
        overflow-y="hidden"
        overflow-x="hidden"
        quarkly-title="Advantages/Features-12"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          grid-gap="5%"
          sm-min-width="280px"
        />
        <Box
          display="flex"
          width="50%"
          justify-content="flex-end"
          lg-justify-content="center"
          lg-width="100%"
          padding="0px 0px 0px 16px"
          align-items="center"
          lg-padding="0px 0px 0px 0px"
          font="20px sans-serif"
        >
          <Box
            width="972px"
            height="589px"
            md-display="flex"
            md-justify-content="center"
            box-shadow="--xl"
            lg-height="auto"
          >
            <Image
              height="589px"
              lg-width="100%"
              width="972px"
              border-radius="8px"
              z-index="9"
              position="relative"
              lg-margin="0px 0px 0px 0px"
              box-shadow="--xxl"
              src="https://yoxemfrux.com/img/2.jpg"
              object-fit="cover"
              lg-height="auto"
              lg-display="block"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          width="45%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 20px 0px"
          sm-margin="32px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="24px 0px 16px 0px"
          lg-flex-direction="column"
          lg-flex-wrap="no-wrap"
          sm-width="100%"
          md-margin="0px 0px 30px 0px"
          lg-padding="36px 0px 16px 0px"
        >
          <Text
            as="h1"
            margin="0px"
            font="normal 600 40px/1.5 --fontFamily-sans"
            color="--light"
            width="100%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
            sm-font="normal 600 40px/1.2 --fontFamily-sans"
            lg-max-width="720px"
          >
            Почему стоит выбрать The Green Expanse?
          </Text>
          <List
            margin="40px 0px 0px 0px"
            padding="0px 0px 0px 0px"
            as="ul"
            sm-margin="24px 0 0 0"
            list-style-type="none"
            font="normal normal 18px/150% sans-serif"
            display="flex"
            flex-direction="column"
            grid-gap="25px"
            lg-margin="32px 0px 0px 0px"
          >
            <Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
              <Text
                padding="0"
                margin="0"
                color="--light"
                font="18px/27px --fontFamily-sans"
                lg-max-width="720px"
              >
                <Span
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="normal 600 16px/1.5 --fontFamily-sans"
                  color="--light"
                >
                  Нетронутые поля:
                </Span>
                <Span
                  opacity="0.9"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="--base"
                  color="--light"
                >
                  Ощутите совершенство игры в гольф на наших безупречно
                  ухоженных полях и фервеях, созданных для того, чтобы бросать
                  вызов и вдохновлять.
                </Span>
              </Text>
            </Box>
            <Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
              <Text
                padding="0"
                margin="0"
                color="--darkL2"
                font="18px/27px --fontFamily-sans"
                lg-max-width="720px"
              >
                <Span
                  font-weight="600"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="normal 600 16px/1.5 --fontFamily-sans"
                  color="--light"
                >
                  Эксклюзивные преимущества для членов клуба:{" "}
                </Span>
                <Span
                  opacity="0.9"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="--base"
                  color="--light"
                >
                  Наслаждайтесь приоритетным доступом, специальными тарифами и
                  мероприятиями только для членов клуба, которые улучшат ваше
                  путешествие в гольф.
                </Span>
              </Text>
            </Box>
            <Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
              <Text
                padding="0"
                margin="0"
                font="18px/27px --fontFamily-sans"
                lg-max-width="720px"
              >
                <Span
                  font-weight="600"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="normal 600 16px/1.5 --fontFamily-sans"
                  color="--light"
                >
                  Семейная атмосфера:{" "}
                </Span>
                <Span
                  opacity="0.9"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="--base"
                  color="--light"
                >
                  Мы считаем, что гольф - это семейное дело. Наши поля и клубные
                  объекты созданы для того, чтобы ими могли наслаждаться игроки
                  всех возрастов.
                </Span>
              </Text>
            </Box>
            <Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
              <Text
                padding="0"
                margin="0"
                font="18px/27px --fontFamily-sans"
                lg-max-width="720px"
              >
                <Span
                  font-weight="600"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="normal 600 16px/1.5 --fontFamily-sans"
                  color="--light"
                >
                  Исключительная кухня:
                </Span>
                <Span
                  opacity="0.9"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="--base"
                  color="--light"
                >
                  Наслаждайтесь вкусом наших изысканных блюд, где каждое блюдо
                  готовится так же тщательно, как и наши курсы.
                </Span>
              </Text>
            </Box>
          </List>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
